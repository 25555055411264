// configurator
export const SET_CONFIGURATOR = 'SET_CONFIGURATOR'
export const SET_NAME = 'SET_NAME'
export const SET_STEPS = 'SET_STEPS'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_NEXT_STEP = 'SET_NEXT_STEP'
export const SET_PREVIEW_IMAGES = 'SET_PREVIEW_IMAGES'
export const SET_PREVIEW_IMAGES_LOADING = 'SET_PREVIEW_IMAGES_LOADING'
export const SET_PREVIEW_MODAL_SHOW = 'SET_PREVIEW_MODAL_SHOW'
export const SET_EXAMPLE_IMAGES = 'SET_EXAMPLE_IMAGES'
export const SET_EXAMPLE_IMAGES_LOADING = 'SET_EXAMPLE_IMAGES_LOADING'
export const SET_EXAMPLE_IMAGE_INDEX = 'SET_EXAMPLE_IMAGE_INDEX'
export const SET_ANIMATIONS = 'SET_ANIMATIONS'
export const SET_LOADING = 'SET_LOADING'
export const SET_PRICE = 'SET_PRICE'
export const SET_LOADING_PRICE = 'SET_LOADING_PRICE'
export const SET_PRICE_ALL = 'SET_PRICE_ALL'
export const SET_LOADING_PRICE_ALL = 'SET_LOADING_PRICE_ALL'
export const SET_DISCOUNT = 'SET_DISCOUNT'
export const UPDATE_AUSLEGER = 'UPDATE_AUSLEGER'
export const SET_LOADING_3D = 'SET_LOADING_3D'
export const SET_BACKSIDE_STATUS = 'SET_BACKSIDE_STATUS'
export const SET_BACKSIDE_BTN = 'SET_BACKSIDE_BTN'
export const SET_DIM_GARAGE = 'SET_DIM_GARAGE'
export const SET_ALTERNATIVE_VARIANTS = 'SET_ALTERNATIVE_VARIANTS'
export const SET_DIMENSION_CAPS_TEXT = 'SET_DIMENSION_CAPS_TEXT'
export const SET_NAVIGATION_MENU = 'SET_NAVIGATION_MENU'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_REGION = 'SET_REGION'
export const SET_SPECIAL_TEXT = 'SET_SPECIAL_TEXT'
export const SET_META_TITLE = 'SET_META_TITLE'
export const SET_PREVIEW_THREED_MODAL_SHOW = 'SET_PREVIEW_THREED_MODAL_SHOW'
// checkout.js
export const RESET = 'RESET'
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_DELIVERY_COUNTRY = 'SET_DELIVERY_COUNTRY'
export const SET_PRICES = 'SET_PRICES'
export const SET_PICKUP = 'SET_PICKUP'
export const SET_SELECTED_PAYMENT_AMOUNT = 'SET_SELECTED_PAYMENT_AMOUNT'
export const TOGGLE_DIFFERENT_ADDRESS = 'TOGGLE_DIFFERENT_ADDRESS'
export const SET_ORDER_SENDING = 'SET_ORDER_SENDING'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const SET_CHECKOUT_ID = 'SET_CHECKOUT_ID'
export const TRUNCATE_CART = 'TRUNCATE_CART'
export const SET_URL = 'SET_URL'
export const UPDATE_URL = 'UPDATE_URL'
export const SET_MONTAGE_COST = 'SET_MONTAGE_COST'
export const SET_MONTAGE_STATUS = 'SET_MONTAGE_STATUS'
export const CHANGE_TYPE_OF_CART = 'CHANGE_TYPE_OF_CART'
export const SET_MONTAGE_BONUS = 'SET_MONTAGE_BONUS'
export const SET_PROMOCODE = 'SET_PROMOCODE'
export const SET_SELECTED_PAYMENT = 'SET_SELECTED_PAYMENT'
// lang.js
export const SET_LOCALE = 'SET_LOCALE'
// menu.js
export const SET_MENU = 'SET_MENU'
export const SET_STATUS_MENU = 'SET_STATUS_MENU'
// footer.js
export const SET_FOOTER = 'SET_FOOTER'
export const SET_STATUS_FOOTER = 'SET_STATUS_FOOTER'
// slider.js
export const UPDATE_SLIDER_FROM = 'UPDATE_SLIDER_FROM'
export const UPDATE_SLIDER_TO = 'UPDATE_SLIDER_TO'
// product.js
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_PRODUCTS = 'SET_PRODUCTS'
// stats.js
export const SET_REFERRER_URL = 'SET_REFERRER_URL'
export const SET_REFERRER = 'SET_REFERRER'
// related.js
export const SET_RELATED = 'SET_RELATED'
// popup.js
export const SET_POPUP_STATUS = 'SET_POPUP_STATUS'
export const SET_TOOLTIP = 'SET_TOOLTIP'
