import * as types from '../mutation-types'
import axios from 'axios'
import Regions from '~/assets/js/regions.json'
// state
export const state = {
  configs: null,
  region: null
}
// getters
export const getters = {
  configs: state => state.configs,
  region: state => state.region
}
// mutations
export const mutations = {
  [types.SET_CONFIG] (state, payload) {
    state.configs = payload
  },
  [types.SET_REGION] (state, payload) {
    state.region = payload
  }
}
// actions
export const actions = {
  getConfig ({ commit }, payload) {
    const apiKey = btoa(process.env.VUE_APP_API_KEY)
    const apiUrl = process.env.VUE_APP_API_URL
    axios.post(apiUrl + 'configs/all', { re: apiKey, name: payload.name }).then(resp => {
      if (resp.data !== null) {
        const data = JSON.parse(resp.data.value)
        commit(types.SET_CONFIG, data)
      }
    })
  },
  async getRegionByCode ({ commit }, payload) {
    // 10115
    const region = await Regions.find((el) => Number.parseInt(el.code) === Number.parseInt(payload))
    console.log(region)
    commit(types.SET_REGION, region)
  }
}
